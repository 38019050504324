import React, { useEffect, useState, useContext, useRef } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useHistory } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import SearchProductList from '../../../Component/ProductCard/product'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import moment from 'moment'
import { getAdsImage, handleRedirectInternal } from '../../../Product/common/components'
import AlertContext from '../../../Product/context/alert/alertContext'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import UserContext from '../../../Product/context/user/userContext'
import { useLocation } from 'react-router-dom'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import GridBuynow from '../../../Component/ProductCard/GridBuynow'
import { Button } from '@material-ui/core'
import Loaders from '../../../Product/components/molecules/Loaders'
import Slider from 'react-slick'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'

const Home = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const userContext = useContext(UserContext)
    const { static_page, getStaticPage } = userContext
    const {
        search_allauctionproducts,
        search_allproducts,
        search_allauctions,
        getLotList,
        getAllSearchAuctions,
        getAllSearchProducts,
    } = productContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const history = useHistory()

    const [lotsPageLoading, setlotsPageLoading] = useState(true)
    const [galleryPageLoading, setgalleryPageLoading] = useState(true)
    const [closedHomeLoading, setclosedHomeLoading] = useState(true)
    const [activeHomeLoading, setactiveHomeLoading] = useState(true)

    const [viewProduct, setViewProduct] = useState([])
    const [buyNowProduct, setBuyNowProduct] = useState([])
    const [activeAuctions, setActiveAuctions] = useState([])
    const [activeHome, setActiveHome] = useState([])
    const sessionTimeOutRef = useRef(null)
    const [viewClosedAuction, setViewClosedAuction] = useState([])
    const [listview, setlistview] = useState(false)
    const [clearRequest, setClearTimeout] = useState(null)
    const [cancelRequest, setCancelRequest] = useState(undefined)
    const cancelToken = axios.CancelToken.source()
    const [auctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby:
            isTabletOrMobile && global?.storeConfigration?.auction_close_soon_mobile?.value
                ? global?.storeConfigration?.auction_close_soon_mobile?.value
                : global.storeConfigration?.auction_search_order?.value
                ? global.storeConfigration.auction_search_order.value
                : '2',
        searchbar: '',
        is_auction: 1,
    })

    const [closedSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '1',
        type: 'closed',
        is_auction: 1,
    })

    const [productSearch] = useState({
        sh_limit: 20,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    const [lotSearch, setLotSearch] = useState({
        sh_limit: 20,
        from: 'auction_only',
        page: 1,
        orderby: '12',
        most_viewed: 1,
        viewers: 1,
    })

    const getAllProducts = async (type) => {
        // getAllSearchProducts(productSearch, 'galleryPage')
        //     .then((res) => {
        //         if (res.success === 'yes' && params.get('product'))
        //             getViewProduct({ product_id: Number(params.get('product')) })
        //     })
        //     .catch((err) => console.log(err, 'watchlist get products err'))
        // setLoading({
        //     activeLoading: false,
        //     buyNowLoading: false,
        //     closedLoading: false,
        // })
        // getAllSearchAuctions(auctionSearch, 'activeHome')
        //     .then((res) => {
        //         if (res.success === 'yes' && params.get('product'))
        //             getViewProduct({ product_id: Number(params.get('product')) })
        //     })
        //     .catch((err) => console.log(err, 'watchlist get products err'))
    }

    useEffect(() => {
        setgalleryPageLoading(true)
        getAllSearchProducts(productSearch, 'galleryPage')
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        if (search_allproducts.from === 'galleryPage') {
            setBuyNowProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setgalleryPageLoading(false)
        }
        if (search_allproducts.from === 'lotsPage') {
            clearTimeout(sessionTimeOutRef.current)
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            if (lotSearch.most_viewed_feature && !search_allproducts.results.length) {
                setLotSearch({
                    ...lotSearch,
                    most_viewed: 1,
                    most_viewed_feature: 0,
                })
            } else {
                setlotsPageLoading(false)
            }
        }
    }, [search_allproducts])

    useEffect(() => {
        setlotsPageLoading(true)
        if (cancelRequest) {
            cancelRequest.cancel()
        }

        setCancelRequest(cancelToken)
        getAllSearchProducts(lotSearch, 'lotsPage')
        sessionTimeOutRef.current = setTimeout(() => {
            getAllSearchProducts(lotSearch, 'lotsPage')
        }, 8000)
    }, [lotSearch, isAuthenticated])

    useEffect(() => {
        setclosedHomeLoading(true)
        if (global?.storeConfigration?.remove_recently_closed?.value != 1) {
            getAllSearchAuctions(closedSearch, 'closedHome')
        }
    }, [closedSearch])

    useEffect(() => {
        setactiveHomeLoading(true)
        getAllSearchAuctions(auctionSearch, 'activeHome')

        if (global?.storeConfigration?.buy_now_home_changes?.value) {
            var body_request = auctionSearch
            body_request.is_auction = 0
            body_request.is_buynow = 1
            getAllSearchAuctions(body_request, 'activeHome_buynow')
        }
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'activeHome') {
            let activeAuctions = search_allauctions.results.length ? search_allauctions.results : []
            setActiveAuctions(
                activeAuctions.filter(
                    (data) => data.market_status == 'open' && data.remainingDay >= 0,
                ),
            )
            // setTimeout(() => {
            setactiveHomeLoading(false)
            // }, 1500)
        }

        if (search_allauctions.from === 'closedHome') {
            setViewClosedAuction(
                search_allauctions.results.length ? search_allauctions.results : [],
            )
            setclosedHomeLoading(false)
        }

        if (search_allauctions.from == 'activeHome_buynow') {
            setActiveHome(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    useEffect(() => {
        if (global?.storeConfigration?.liq_home_page_banner_changes?.value == 1) {
            getStaticPage(
                {
                    page_id: 'home_banner_text',
                },
                1,
            )
        }
    }, [])

    const settings = {
        dots: true,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }

    const imageChangeInterval = global?.pluginConfiguration?.advertisement?.refresh_key * 60000 // Convert minutes to milliseconds

    const isMobile = useMediaQuery({
        query: '(max-width: 500px)',
    })

    const [homeAds, setHomeAds] = useState({
        deskHomeTop: '',
        deskHomeMid: '',
        mobHomeTop: '',
        mobHomeMid: '',
        deskHomeBottom: '',
        mobHomeBottom: '',
    })

    const [activeHomeAdsIndex, setActiveHomeAdsIndex] = useState({
        deskHomeTop: 0,
        deskHomeMid: 0,
        mobHomeTop: 0,
        mobHomeMid: 0,
        deskHomeBottom: 0,
        mobHomeBottom: 0,
    })

    function updateRandomIndex(array) {
        const randomIndex = Math.floor(Math.random() * array.length)
        return randomIndex
    }

    useEffect(() => {
        setHomeAds((prevAucAds) => ({
            deskHomeTop: getAdsImage(1, 1, 1),
            deskHomeMid: getAdsImage(1, 1, 2),
            mobHomeTop: getAdsImage(2, 1, 1),
            mobHomeMid: getAdsImage(2, 1, 2),
            deskHomeBottom: getAdsImage(1, 1, 5),
            mobHomeBottom: getAdsImage(2, 1, 5),
        }))
    }, [])

    useEffect(() => {
        setActiveHomeAdsIndex((prevActiveIndex) => ({
            deskHomeTop: updateRandomIndex(homeAds?.deskHomeTop),
            deskHomeMid: updateRandomIndex(homeAds?.deskHomeMid),
            mobHomeTop: updateRandomIndex(homeAds?.mobHomeTop),
            mobHomeMid: updateRandomIndex(homeAds?.mobHomeMid),
            deskHomeBottom: updateRandomIndex(homeAds?.deskHomeBottom),
            mobHomeBottom: updateRandomIndex(homeAds?.mobHomeBottom),
        }))
    }, [homeAds])

    return (
        <Layout props={props}>
            <div className="home">
                {global?.storeConfigration?.liq_home_page_banner_changes?.value == 1 ? (
                    <div className="siteBanner multiple">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: static_page.content,
                            }}
                        ></div>
                    </div>
                ) : global.multipleBannersFields.length ? (
                    <div className="siteBanner multiple">
                        {
                            <Slider {...settings}>
                                {global.multipleBannersFields?.map((data, index) => (
                                    <>
                                        <img
                                            src={
                                                process.env.REACT_APP_AUCTION_IMAGE_URL +
                                                '/' +
                                                data?.image_name
                                            }
                                        />
                                        <div className="bannerContent">
                                            <h2 className="">
                                                {JSON.parse(data.fields ? data.fields : '{}').title}
                                            </h2>
                                            <p className="pb-4">
                                                {
                                                    JSON.parse(data.fields ? data.fields : '{}')
                                                        .description
                                                }
                                            </p>
                                            {JSON.parse(data.fields ? data.fields : '{}').btn_text
                                                ?.length > 0 && (
                                                <PrimaryButton
                                                    label={
                                                        isAuthenticated
                                                            ? JSON.parse(
                                                                  data.fields ? data.fields : '{}',
                                                              ).btn_text
                                                            : 'Sign Up'
                                                    }
                                                    type="button"
                                                    onClick={() =>
                                                        !isAuthenticated
                                                            ? handleRedirectInternal(
                                                                  history,
                                                                  'sign-up',
                                                              )
                                                            : (window.location.href = JSON.parse(
                                                                  global.multipleBannersFields[
                                                                      index
                                                                  ].fields
                                                                      ? global
                                                                            .multipleBannersFields[
                                                                            index
                                                                        ].fields
                                                                      : '{}',
                                                              ).btn_link)
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                ))}
                            </Slider>
                        }
                    </div>
                ) : (
                    <div className="siteBanner">
                        <LazyLoadImage
                            src={global?.storeDetails?.bannerValue}
                            alt={`${global?.storeDetails?.name} banner`}
                            effect="blur"
                            height="100%"
                            width="100%"
                        />
                        <div className="bannerContent">
                            <h2>{global?.storeDetails?.description}</h2>
                            <PrimaryButton
                                label="Explore Now"
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        `${isAuthenticated ? 'search' : 'sign-up'}`,
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
                {/* <div className="siteBanner">
                    <img
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                    />
                    <div className="bannerContent">
                        <h2>{global?.storeDetails?.name}</h2>
                        <p>{global?.storeDetails?.description}</p>
                        <SecondaryButton
                            label="Get Started"
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    `${isAuthenticated ? 'search' : 'sign-up'}`,
                                )
                            }
                        />
                    </div>
                </div> */}

                <div className="container auctionListCnt">
                    {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                        <div className="siteAds adsHome">
                            {isMobile
                                ? homeAds?.mobHomeTop[activeHomeAdsIndex?.mobHomeTop]
                                : homeAds?.deskHomeTop[activeHomeAdsIndex?.deskHomeTop]}
                        </div>
                    ) : null}
                    {global?.storeConfigration?.buy_now_home_changes?.value ? (
                        <>
                            {activeHomeLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={activeHomeLoading}
                                    loop={5}
                                />
                            ) : activeAuctions.length > 0 ? (
                                <div className="twoCart">
                                    <div className="search-wrapper">
                                        <h2 className="auctionListTitle">
                                            {isTabletOrMobile &&
                                            global?.storeConfigration?.auction_close_soon_mobile
                                                ?.value
                                                ? 'Current Auctions'
                                                : 'Recently Listed Auctions'}
                                        </h2>

                                        <div className="gridContainer liquidationAuctions">
                                            {activeAuctions.map((data, index) => (
                                                <GridAuction
                                                    key={index}
                                                    data={data}
                                                    details={`search/product-buyer-auction/${data.id}`}
                                                    timerTheme="multiseller"
                                                    theme="multiseller"
                                                    // lotCount={data.lotCount ? data.lotCount : 0}
                                                />
                                            ))}
                                        </div>

                                        <Button
                                            className="viewAll"
                                            onClick={() =>
                                                handleRedirectInternal(history, `auctions`)
                                            }
                                        >
                                            View All{' '}
                                            <span className="material-icons">arrow_right_alt</span>
                                        </Button>
                                    </div>
                                    <div>
                                        <h2 className="auctionListTitle mobiusMart">MobiusMart</h2>

                                        <div className="gridContainer liquidationAuctions mobiusMartCard">
                                            {activeHome.map((data, index) => (
                                                <>
                                                    {index == 0 ? (
                                                        <GridBuynow
                                                            key={index}
                                                            data={data}
                                                            details={`search/product-buyer-auction/${data.id}`}
                                                            timerTheme="multiseller"
                                                            theme="multiseller"
                                                            type="buynow"
                                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                                            remove_description={0}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        <>
                            {activeHomeLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={activeHomeLoading}
                                    loop={5}
                                />
                            ) : activeAuctions.length > 0 ? (
                                <div className="search-wrapper">
                                    <h2 className="auctionListTitle">
                                        {isTabletOrMobile &&
                                        global?.storeConfigration?.auction_close_soon_mobile?.value
                                            ? 'Current Auctions'
                                            : 'Recently Listed Auctions'}
                                    </h2>

                                    <div className="gridContainer liquidationAuctions">
                                        {activeAuctions.map((data, index) => (
                                            <GridAuction
                                                key={index}
                                                data={data}
                                                details={`search/product-buyer-auction/${data.id}`}
                                                timerTheme="multiseller"
                                                theme="multiseller"
                                                // lotCount={data.lotCount ? data.lotCount : 0}
                                            />
                                        ))}
                                    </div>
                                    <Button
                                        className="viewAll"
                                        onClick={() => handleRedirectInternal(history, `auctions`)}
                                    >
                                        View All{' '}
                                        <span className="material-icons">arrow_right_alt</span>
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    )}

                    {global?.storeConfigration?.hide_most_viewed?.value == 1 ? null : (
                        <>
                            {lotsPageLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={lotsPageLoading}
                                    loop={5}
                                />
                            ) : viewProduct.length > 0 ? (
                                <div className="search-wrapper">
                                    <>
                                        <h2 className="auctionListTitle">Most Viewed Lots</h2>
                                        <SearchProductList
                                            listview={listview}
                                            searchResult={viewProduct}
                                            className="liquidationBuyCard"
                                            getAllProducts={() => getAllProducts('auctions')}
                                            type="auction"
                                            fromComponent="home"
                                        />
                                        <Button
                                            className="viewAll"
                                            onClick={() =>
                                                handleRedirectInternal(history, `search`)
                                            }
                                        >
                                            View All{' '}
                                            <span className="material-icons">arrow_right_alt</span>
                                        </Button>
                                    </>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                    {galleryPageLoading ? (
                        <Loaders name="product_grid_view" isLoading={galleryPageLoading} loop={5} />
                    ) : buyNowProduct.length > 0 ? (
                        <>
                            {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                <>
                                    <h2 className="auctionListTitle">
                                        {global.storeConfigration?.buy_now_change_text?.value
                                            ? global.storeConfigration?.buy_now_change_text?.value
                                            : 'Shop Our Gallery'}
                                    </h2>
                                    <div className="search-wrapper">
                                        <SearchProductList
                                            listview={listview}
                                            searchResult={buyNowProduct}
                                            className="liquidationBuyCard"
                                            type="buynow"
                                            noTimer={true}
                                            getAllProducts={() => getAllProducts('buynow')}
                                            fromComponent="home"
                                        />
                                        <Button
                                            className="viewAll"
                                            onClick={() =>
                                                handleRedirectInternal(history, `gallery`)
                                            }
                                        >
                                            View All{' '}
                                            <span className="material-icons">arrow_right_alt</span>
                                        </Button>
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : (
                        ''
                    )}
                    {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                        <div className="siteAds adsHome">
                            {isMobile
                                ? homeAds?.mobHomeMid[activeHomeAdsIndex?.mobHomeMid]
                                : homeAds?.deskHomeMid[activeHomeAdsIndex?.deskHomeMid]}
                        </div>
                    ) : null}
                    {global?.storeConfigration?.remove_recently_closed?.value == 1 ? null : (
                        <>
                            {closedHomeLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={closedHomeLoading}
                                    loop={5}
                                />
                            ) : viewClosedAuction.length > 0 ? (
                                <div className="search-wrapper">
                                    <h2 className="auctionListTitle">Recently Closed Auctions</h2>

                                    <div className="gridContainer liquidationAuctions">
                                        {/*eslint-disable*/}
                                        {viewClosedAuction.map(
                                            (data, index) =>
                                                index <
                                                    (global?.storeConfigration
                                                        ?.recently_closed_auctions_count?.value ||
                                                        1) && (
                                                    <GridAuction
                                                        key={index}
                                                        data={data}
                                                        details={`search/product-buyer-auction/${data.id}`}
                                                        timerTheme="multiseller"
                                                        theme="multiseller"
                                                        // lotCount={data.lotCount ? data.lotCount : 0}
                                                    />
                                                ),
                                        )}
                                    </div>
                                    <Button
                                        className="viewAll"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `auctions?closed=${closedSearch.type}`,
                                            )
                                        }
                                    >
                                        View All{' '}
                                        <span className="material-icons">arrow_right_alt</span>
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                    {viewClosedAuction.length == 0 &&
                    buyNowProduct.length == 0 &&
                    viewProduct.length == 0 &&
                    activeAuctions.length == 0 ? (
                        <div className="noContent">
                            <img src="/assets/svg/noContent.svg" alt="No Content" />
                            <h3>We are getting our inventory ready.</h3>
                            <h3>Please visit back soon!</h3>
                        </div>
                    ) : (
                        ''
                    )}

                    {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                        <div className="siteAds adsHome">
                            {isMobile
                                ? homeAds?.mobHomeBottom[activeHomeAdsIndex?.mobHomeBottom]
                                : homeAds?.deskHomeBottom[activeHomeAdsIndex?.deskHomeBottom]}
                        </div>
                    ) : null}
                </div>
            </div>
        </Layout>
    )
}

export default Home
