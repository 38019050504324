import React from 'react'
import CustomDialog from '../../../Product/components/organisms/Dialog'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useState } from 'react'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useContext } from 'react'
import schedulePickupContext from '../../../Product/context/plugin/schedulePickup/schedulePickupContext'
import { useEffect } from 'react'
import { mapData } from '../../../Product/common/components'
import authContext from '../../../Product/context/auth/authContext'
const MultiCheckIn = ({ pickupData, trigger, setTrigger, curbside }) => {
    const [checkInStep, setCheckInStep] = useState(0)
    const [checkInModal, setCheckInModal] = useState(false)
    const [exist, setExist] = useState(pickupData?.car_make ? true : false)
    const [loading, setLoading] = useState()
    const { addCheckIn, responseStatus } = useContext(schedulePickupContext)
    const { user } = useContext(authContext)
    const checkInValidationSchema = Yup.object().shape({
        parking_lot_no: Yup.string().when('type', {
            is: '2' || 2,
            then: Yup.string()
                .trim()
                .matches(/^[a-zA-Z0-9]*$/g, 'Special characters are not allowed')
                .max(20, '20 characters max')
                .required('Required'),
        }),
        car_make: Yup.string()
            .trim()
            .matches(/^[a-zA-Z0-9]*$/g, 'Special characters are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
        model: Yup.string()
            .trim()
            .matches(/^[a-zA-Z0-9]*$/g, 'Special characters are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
        color: Yup.string()
            .trim()
            .matches(/^[a-zA-Z]*$/g, 'Special characters are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
    })

    const formikCheckIn = useFormik({
        initialValues: {
            type: pickupData?.type || 1,
            parking_lot_no: pickupData?.parking_lot_no,
            car_make: pickupData?.car_make,
            model: pickupData?.model,
            color: pickupData?.color,
            schedule_id: pickupData?.id?.join(','),
        },
        validationSchema: checkInValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true)
            addCheckIn({ ...values, site_id: global?.storeDetails?.site_id, user_id: user?.id })
        },
    })
    const checkInInfo = [
        {
            title: '',
            type: 'radio',
            class: 'col-12',
            int: 1,
            item: [
                { id: 1, description: 'Office' },
                { id: 2, description: 'Parking Lot' },
            ],
            name: 'type',
            formik: formikCheckIn,
        },
        {
            label: 'Parking Lot No',
            name: 'parking_lot_no',
            type: 'select',
            placeholder: 'Parking Lot No',
            class: formikCheckIn.values.type == 1 ? 'd-none' : 'col-6',
            options: curbside?.map((val) => ({
                show: val.name,
                value: val.name,
            })),
            formik: formikCheckIn,
        },
        {
            label: 'Car Make',
            name: 'car_make',
            type: 'text',
            placeholder: 'Car Make',
            class: 'col-6',
            formik: formikCheckIn,
        },
        {
            label: 'Model',
            name: 'model',
            type: 'text',
            placeholder: 'Model',
            class: 'col-6',
            formik: formikCheckIn,
        },
        {
            label: 'Color',
            name: 'color',
            type: 'text',
            placeholder: 'Color',
            class: 'col-6',
            formik: formikCheckIn,
        },
    ]

    const manageCheckInModal = () => {
        setCheckInModal(!checkInModal)
        setCheckInStep(0)
        formikCheckIn.resetForm()
        formikCheckIn.setTouched({})
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addCheckIn') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    setCheckInStep(1)
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        setExist(pickupData?.car_make ? true : false)
        formikCheckIn.setValues({
            type: pickupData?.type,
            parking_lot_no: pickupData?.parking_lot_no,
            car_make: pickupData?.car_make,
            model: pickupData?.model,
            color: pickupData?.color,
            schedule_id: pickupData?.id,
        })
    }, [pickupData])
    useEffect(() => {
        if (formikCheckIn.values.type == 1) {
            formikCheckIn.setFieldValue('parking_lot_no', '')
        }
    }, [formikCheckIn.values.type])
    return (
        <div>
            {exist ? (
                <>
                    <PrimaryButton btnSize="schedulePickupBtn mb-3" onClick={manageCheckInModal}>
                        <span className="material-icons-outlined mr-2">login</span>
                        Edit Check In
                    </PrimaryButton>
                </>
            ) : (
                <PrimaryButton btnSize="schedulePickupBtn mb-3" onClick={manageCheckInModal}>
                    <span className="material-icons-outlined mr-2">login</span>
                    Check In
                </PrimaryButton>
            )}
            <CustomDialog
                title={exist ? 'Edit Check In' : 'Check In'}
                open={checkInModal}
                function={() => {
                    manageCheckInModal()
                    setTrigger(!trigger)
                }}
                className="scheduleModal"
                closeBtn={true}
                maxWidth="md"
            >
                <div className="scheduleModalCnt">
                    {checkInStep === 0 ? (
                        <div className="smDetails">
                            <div className="smMain">
                                <form onSubmit={formikCheckIn.handleSubmit}>
                                    <div className="row">
                                        {Object.values(mapData(checkInInfo))}
                                        <div className="col-12">
                                            <PrimaryButton
                                                label={loading ? 'Loading...' : 'Check In'}
                                                type="submit"
                                                disabled={loading}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : checkInStep === 1 ? (
                        <div className="smDate">
                            <div className="smMain booked text-center">
                                <h4>Pickup Check {exist ? 'Edited' : 'Confirmed'}!</h4>
                                <p>Your items are being prepared for a smooth collection.</p>
                                <p>We&apos;ll notify you once everything is ready.</p>
                                <p>Thank you for your patience!</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </CustomDialog>
        </div>
    )
}

export default MultiCheckIn
