import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import PrimaryButton from '../../../../Product/components/atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import SecondaryButton from '../../../../Product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '../../../../product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BuyerContext from '../../../../Product/context/buyer/buyerContext'
import AuthContext from '../../../../Product/context/auth/authContext'
import StripeCardContext from '../../../../Product/context/stripe/card/cardContext'
import DirectStripeContext from '../../../../Product/context/directStripe/directStripeContext'
import AlertContext from '../../../../Product/context/alert/alertContext'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../../organisms/Dialog'
import { handleRedirectInternal } from '../../../common/components'
import { currencyFormat } from '../../../common/components'
import { mapData } from '../../../../Product/common/components'
import CurrencyConverterFormat from '../../../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'

const Bidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { t } = useTranslation()
    const { setAlert } = alertContext
    const { get_all_card_details, get_skyflow_cards } = useContext(StripeCardContext)
    const { usercards } = useContext(DirectStripeContext)
    const [bidpopuptext, setBidpopuptext] = useState('')
    let { bidConfirm } = buyerContext
    const { user, isAuthenticated, userCount, updateReferral } = authContext
    const cbidnot = localStorage.setItem('cbidnot', props.data.cbidnot)
    const [cards, setCards] = useState([])
    const [product, setProduct] = useState()
    const [biddingAmount, setBiddingAmount] = useState()
    const [biderrpopup, setBiderrpopup] = useState(false)
    const history = useHistory()
    const [bidding_disable, setBiddingdisable] = useState(false)
    const [toggleDialog, setToggleDialog] = useState(false)
    var min_amount = 0,
        max_amount = 0,
        intermediate_value = 0
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        // console.log(props.data, 'this is props data')
        setProduct(props.data)
    }, [props.data])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .positive('Please enter only numbers for your bid')
            .integer('Amount Should be an integer')
            .typeError('Please enter only numbers for your bid')
            .min(
                product ? parseInt(product.next_bid) : 0,
                `${'Min bid'} ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            )
            .test('is-decimal', 'Cannot be a decimal', (value) =>
                value ? typeof value === 'number' && value % 1 === 0 : true,
            )
            .required('Enter bid amount'),
    })

    const validationArrayDecimal = Yup.object({
        wsprice: Yup.number()
            .positive('Please enter only numbers for your bid')
            .typeError('Please enter only numbers for your bid')
            .min(
                product ? parseInt(product.next_bid) : 0,
                `${'Min bid'} ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            )
            .required('Enter bid amount'),
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: 0,
            hard_bid: '',
            bid_increment: 0,
            email: '',
            first_name: '',
            last_name: '',
            producturl: '',
        },
        validationSchema:
            global.storeDetails.site_id == 'AS0282' || global.storeDetails.site_id == 'AS0282'
                ? validationArrayDecimal
                : validationArray,
        onSubmit: (values) => {
            if (
                cards.length ||
                global.storeDetails?.theme == 4 ||
                global.storeConfigration?.credit_card_no_need?.value == 1
            ) {
                //if (cards[0]?.customer == user?.card_paymentid) {
                if (new Date(props.data.date_added) > new Date()) {
                    return setAlert('Wait until auction starts!', 'warning')
                }

                if (props.removePopup) {
                    bidConfirm(values)
                    formik.setTouched({})
                    formik.setFieldValue('wsprice', '')
                } else if (toggleDialog) {
                    values.producturl = `${window.origin}/${global.storeDetails.id}/productView/${values.id}`
                    if (global.storeConfigration?.bidding_sound?.value == 1) {
                        if (props.audio != '' && props.audio != undefined) {
                            props.audio.play()
                        }
                    }
                    bidConfirm(values)
                    formik.setTouched({})
                    setToggleDialog(false)
                    formik.setFieldValue('wsprice', '')
                    if (
                        global?.pluginConfiguration?.referral?.enable == 1 &&
                        userCount?.total_bids == 0 &&
                        global?.storeConfigration?.bid_referral_update?.value == 1
                    ) {
                        updateReferral()
                    }
                } else {
                    formik.setSubmitting(false)
                    setToggleDialog(true)
                }
                //}
            } else {
                setAlert('Add a card to bid!', 'error')
                handleRedirectInternal(history, 'dashboard/cards')
            }
        },
    })

    useEffect(() => {
        formik.values.hard_bid = props.hard
    }, [props.hard])

    useEffect(() => {
        if (global.storeConfigration?.skyflow_integration?.value == 1) {
            if (get_skyflow_cards) {
                setCards(get_skyflow_cards)
            }
        } else {
            if (get_all_card_details) {
                setCards(get_all_card_details.records)
            }
        }
    }, [get_all_card_details, get_skyflow_cards])

    useEffect(() => {
        if (usercards?.length) {
            setCards(usercards)
        }
    }, [usercards])

    useEffect(() => {
        if (product && Number(product.next_bid) >= Number(formik.values.wsprice)) {
            formik.setFieldValue('wsprice', '')
            if (toggleDialog) {
                setAlert('Current Bid Changed Now!', 'warning')
                setToggleDialog(false)
            }
        } else {
            setToggleDialog(false)
        }
    }, [product])
    useEffect(() => {
        if (product) {
            formik.setFieldValue('id', product.id)
            formik.setFieldValue('bid_increment', product.incrementamt)
        }
    }, [product])

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                formik.setFieldValue('email', user.email)
                formik.setFieldValue('first_name', user.first_name)
                formik.setFieldValue('last_name', user.last_name)
                if (
                    global.storeConfigration?.suspended_user_access?.value == 1 &&
                    user?.status == 'suspended'
                ) {
                    setBiddingdisable(true)
                }
            }
        }
    }, [isAuthenticated, user])

    const checkbiddingamount = (e) => {
        if (
            localStorage.getItem('Browsertype') == 'Safari' ||
            localStorage.getItem('Browsertype') == 'Mobile Safari'
        ) {
            if (parseInt(formik.values.wsprice) > parseInt(product.next_bid)) {
                intermediate_value =
                    parseInt(product.incrementamt ? product.incrementamt : 1) *
                    Math.round(
                        parseInt(parseInt(formik.values.wsprice) - parseInt(product.wprice)) /
                            parseInt(product.incrementamt),
                    )
                intermediate_value = parseInt(intermediate_value) + parseInt(product.wprice)
                if (parseInt(formik.values.wsprice) == intermediate_value) {
                    return false
                } else if (parseInt(formik.values.wsprice) < parseInt(intermediate_value)) {
                    max_amount = intermediate_value
                    min_amount = intermediate_value - parseInt(product.incrementamt)
                    setAlert(
                        `Please enter a valid value.The two nearest valid values are ${min_amount} and ${max_amount}`,
                        'error',
                    )
                } else if (parseInt(formik.values.wsprice) > parseInt(intermediate_value)) {
                    min_amount = intermediate_value
                    max_amount = intermediate_value + parseInt(product.incrementamt)
                    setAlert(
                        `Please enter a valid value.The two nearest valid values are ${min_amount} and ${max_amount}`,
                        'error',
                    )
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const bidAmount = [
        {
            label: t('enter_bid'),
            name: 'wsprice',
            type: global?.storeConfigration?.bid_box_type?.value
                ? global?.storeConfigration?.bid_box_type?.value
                : 'number',
            placeholder:
                product && product.next_bid ? `${currencyFormat(product.next_bid, '', '', 0)}` : '',
            class: '',
            size: props.inputSize ? props.inputSize : 'small',
            autoFocus: false,
            pattern:
                global.storeDetails.site_id == 'AS0282' || global.storeDetails.site_id == 'AS0282'
                    ? '[0-9]*[.][0-9]*'
                    : '[0-9]*',
            formik: formik,
            onChange: (e) => {
                const { value } = e.target
                // const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/
                // if (!value || regex.test(value.toString())) {
                //     formik.setFieldValue('wsprice', value)
                // }
                value.replace(/[^0-9]+/g, '')
                formik.setFieldValue('wsprice', value)
            },
            step:
                global.storeDetails.site_id == 'AS0282' || global.storeDetails.site_id == 'AS0282'
                    ? 0.01
                    : global.storeDetails.theme == 4
                    ? 0
                    : product?.incrementamt,
            min: global.storeDetails.theme == 4 ? 0 : product?.next_bid,
            onKeyup: (e) => {
                checkbiddingamount(e)
            },
        },
    ]

    useEffect(() => {
        setBidpopuptext(
            global.storeConfigration?.bid_confirmation_text?.value
                ? global.storeConfigration.bid_confirmation_text.value
                : '',
        )
    }, [])
    // console.log(product, 'this is product')
    useEffect(() => {
        if (bidpopuptext != '') {
            var bid_text = global.storeConfigration?.bid_confirmation_text?.value.replace(
                /{{amount}}/g,
                currencyFormat(formik.values.wsprice),
            )

            setBidpopuptext(bid_text)
        }
        setBiddingAmount(formik.values.wsprice)
    }, [formik.values.wsprice])
    // console.log(biddingAmount, 'formik.values.wsprice')
    const checkbiderrorpopup = (event) => {
        event.preventDefault()
        if (
            parseInt(product.next_bid) > parseInt(formik.values.wsprice) &&
            global.storeDetails.theme == 4
        ) {
            setBiderrpopup(true)
            formik.handleSubmit()
        } else {
            formik.handleSubmit()
        }
    }

    const handleBidButton = (bidValue) => {
        formik.values.wsprice = bidValue
        if (formik.values.wsprice != '') {
            formik.handleSubmit()
        }
    }

    useEffect(() => {
        if (global.storeConfigration?.prepopulate_bid?.value == '1') {
            formik.setFieldValue('wsprice', parseInt(product?.next_bid))
        }
    }, [product, product?.next_bid])

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={checkbiderrorpopup}>
                            {isAuthenticated ? (
                                <>
                                    <div
                                        className={`biddingCnt d-flex justify-content-between align-items-flex-start ${
                                            !isAuthenticated && 'notLogged'
                                        }`}
                                    >
                                        {Object.values(mapData(bidAmount))}
                                        {product.bidtopstatus === 'winner' ? (
                                            <SecondaryButton
                                                label={
                                                    formik.values.hard_bid === '1'
                                                        ? 'Increase hard bid'
                                                        : 'Increase proxy bid'
                                                }
                                                type="submit"
                                                btnSize="small"
                                            />
                                        ) : props.removePopup ? (
                                            <PrimaryButton
                                                onClick={formik.handleSubmit}
                                                type="submit"
                                                disabled={bidding_disable}
                                                label={
                                                    formik.values.hard_bid === '1'
                                                        ? props.shortText
                                                            ? props.shortText
                                                            : t('place_hard_bid')
                                                        : props.shortText
                                                        ? props.shortText
                                                        : 'Place proxy bid'
                                                }
                                                btnSize="small"
                                            />
                                        ) : (
                                            <PrimaryButton
                                                label={
                                                    formik.values.hard_bid === '1'
                                                        ? props.shortText
                                                            ? props.shortText
                                                            : t('place_hard_bid')
                                                        : props.shortText
                                                        ? props.shortText
                                                        : 'Place proxy bid'
                                                }
                                                type="submit"
                                                btnSize="small"
                                                disabled={bidding_disable}
                                            />
                                        )}
                                        {props.enableNextBid && (
                                            <>
                                                <p className="m-0 pt-3 text-center mx-3 orTxtWrpr">
                                                    {t('or')}
                                                </p>
                                                <div className="plcNxtBid">
                                                    <PrimaryButton
                                                        onClick={() =>
                                                            handleBidButton(product.next_bid)
                                                        }
                                                        label={`${t(
                                                            'place_bid',
                                                        )} - ${currencyFormat(product.next_bid)}`}
                                                        btnSize="small"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <PrimaryButton
                                        label={t('login_to_bid')}
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                </div>
                            )}
                        </form>
                    ) : null}

                    {!props.removePopup ? (
                        <CustomDialog
                            title={t('confirm_bid')}
                            open={toggleDialog}
                            function={changeDialogStatus}
                        >
                            {global.storeConfigration?.bid_confirmation_text?.value ? (
                                <>
                                    <div
                                        className="amountPop"
                                        dangerouslySetInnerHTML={{
                                            __html: bidpopuptext,
                                        }}
                                    ></div>
                                    {global.storeConfigration?.bidder_pay_notification?.value ==
                                        1 && (
                                        <>
                                            {global.storeDetails.theme === 10 &&
                                            props.data.cbidtext !== 'Starting Price' ? (
                                                <>
                                                    {localStorage.getItem('cbidnot') ||
                                                    props.data.bid_or_not ? null : (
                                                        <div className="alrtTotWrpr">
                                                            <span className="material-icons">
                                                                warning
                                                            </span>
                                                            You will be charged an additional of
                                                            3.1% + 30 cents as transaction fee
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {props.data.cbidnot ||
                                                    props.data.bid_or_not ? null : (
                                                        <div className="alrtTotWrpr">
                                                            <span className="material-icons">
                                                                warning
                                                            </span>
                                                            You will be charged an additional of{' '}
                                                            {`${
                                                                global.storeConfigration
                                                                    ?.charge_addn?.value + '%' ||
                                                                '3.1%'
                                                            }`}{' '}
                                                            + 30 cents per purchase ONLY IF YOU WIN
                                                            THIS ITEM
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <h5>{'You are about to place a bid'}!</h5>
                                    <h5>
                                        {'Please confirm if you want to continue with this action'}
                                    </h5>
                                    {global.storeConfigration?.bidder_pay_notification?.value ==
                                        1 && (
                                        <>
                                            {global.storeDetails.theme === 10 &&
                                            props.data.cbidtext !== 'Starting Price' ? (
                                                <>
                                                    {localStorage.getItem('cbidnot') ||
                                                    props.data.bid_or_not ? null : (
                                                        <div className="alrtTotWrpr">
                                                            <span className="material-icons">
                                                                warning
                                                            </span>
                                                            You will be charged an additional of
                                                            3.1% + 30 cents as transaction fee
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {props.data.cbidnot ||
                                                    props.data.bid_or_not ? null : (
                                                        <div className="alrtTotWrpr">
                                                            <span className="material-icons">
                                                                warning
                                                            </span>
                                                            You will be charged an additional of
                                                            3.1% + 30 cents as transaction fee
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            <CurrencyConverterFormat amount={formik.values.wsprice} />
                            <div className="actionWrapper">
                                <Button
                                    className="cancelButton"
                                    onClick={() => setToggleDialog(false)}
                                >
                                    {t('cancel')}
                                </Button>
                                <PrimaryButton
                                    onClick={formik.handleSubmit}
                                    type="button"
                                    disabled={formik.isSubmitting}
                                    label={t('submit')}
                                />
                            </div>
                        </CustomDialog>
                    ) : null}
                    <CustomDialog
                        title={'Bid Amount Error'}
                        open={biderrpopup}
                        function={() => setBiderrpopup(false)}
                    >
                        {/* {global.storeConfigration?.bid_error_text?.value ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: bidpopuptext,
                                }}
                            ></div>
                        ) : ( */}
                        <>
                            {/* <h5>{'You are about to place a bid'}!</h5> */}
                            <h5>{`Please Enter ${currencyFormat(
                                product.next_bid,
                            )} or above.ie In Increments of ${currencyFormat(
                                formik.values.bid_increment,
                            )}`}</h5>
                        </>
                        {/* )} */}
                        <div className="actionWrapper">
                            {/* <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button> */}
                            <PrimaryButton
                                onClick={() => setBiderrpopup(false)}
                                type="button"
                                //disabled={formik.isSubmitting}
                                label={'Ok'}
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
