import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import PrimaryButton from '../../../../Product/components/atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import SecondaryButton from '../../../../Product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '../../../../product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import BuyerContext from '../../../../Product/context/buyer/buyerContext'
import AuthContext from '../../../../Product/context/auth/authContext'
import StripeCardContext from '../../../../Product/context/stripe/card/cardContext'
import AlertContext from '../../../../Product/context/alert/alertContext'

import CustomDialog from '../../organisms/Dialog'
import { handleRedirectInternal } from '../../../common/components'
import { currencyFormat } from '../../../common/components'
import { mapData } from '../../../../Product/common/components'
import SnagTimer from '../../../../Product/common/SnagTimer'
import { socket } from '../../../common/socket'
import moment from 'moment'
import { useRef } from 'react'
const Bidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const [dropPrice, setDropPrice] = useState(0)
    const { setAlert } = alertContext
    const { get_all_card_details } = useContext(StripeCardContext)
    const [bidpopuptext, setBidpopuptext] = useState('')
    let { bidConfirm, bidReverseConfirm, bidLikeSnag, responseStatus, clearResponse } = buyerContext
    const { user, isAuthenticated } = authContext
    const [showtimer, setShowTimer] = useState(false)
    const [cards, setCards] = useState([])
    const [product, setProduct] = useState()
    const history = useHistory()
    const [reverse_nextbid, setReverseNextBid] = useState(0)
    const [toggleDialog, setToggleDialog] = useState(false)
    const [servertime, setServertime] = useState('')
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        // console.log(props.data, 'this is props data')
        setProduct(props.data)
    }, [props.data])

    // useEffect(() => {
    //     if (props.timer) {
    //         console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn', props.timer.future)
    //         if (!props.timer.future) {
    //             setShowTimer(true)
    //         }
    //     }
    // }, [props.timer])
    const viewProductRef = useRef(product)

    useEffect(() => {
        viewProductRef.current = product
    })

    useEffect(() => {
        if (socket) {
            socket.on('sliservertime', (data) => {
                let serverTime = new Date()
                if (moment(data.dTime).isValid()) {
                    setServertime(new Date(data.dTime))
                    serverTime = new Date(data.dTime)
                }
                let startDate = new Date(serverTime)
                let addDate = new Date(viewProductRef.current.date_added)
                let incrementedTime = addDate.getTime()
                let newaddDate = new Date(incrementedTime)
                if (newaddDate < startDate) {
                    setShowTimer(true)
                } else {
                    setShowTimer(false)
                }
            })
            return () => {
                socket.off('sliservertime', (data) => {
                    let serverTime = new Date()
                    if (moment(data.dTime).isValid()) {
                        setServertime(new Date(data.dTime))
                        serverTime = new Date(data.dTime)
                    }
                    let startDate = new Date(serverTime)
                    let addDate = new Date(viewProductRef.current.date_added)
                    let incrementedTime = addDate.getTime()
                    let newaddDate = new Date(incrementedTime)
                    if (newaddDate < startDate) {
                        setShowTimer(true)
                    } else {
                        setShowTimer(false)
                    }
                })
            }
        }
    }, [socket])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(
                product ? parseInt(product.next_bid) : 0,
                `${'Min bid'} ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            )
            .test('is-decimal', 'Cannot be a decimal', (value) =>
                value ? typeof value === 'number' && value % 1 === 0 : true,
            )
            .required('Enter bid amount'),
    })
    const validationArrayReverseauction = Yup.object({
        wsprice: Yup.number()
            .max(
                product ? parseInt(reverse_nextbid) : 0,
                `${'Place Bid Lower Then'} ${
                    product ? currencyFormat(reverse_nextbid) : currencyFormat(0)
                }`,
            )
            .test('is-decimal', 'Cannot be a decimal', (value) =>
                value ? typeof value === 'number' && value % 1 === 0 : true,
            )
            .required('Enter bid amount'),
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: 0,
            hard_bid: '',
            bid_increment: 0,
            email: '',
            first_name: '',
            last_name: '',
            producturl: '',
        },
        validationSchema:
            parseInt(product?.content_head5) == 1 ? validationArrayReverseauction : validationArray,
        onSubmit: (values) => {
            if (cards.length) {
                if (new Date(props.data.date_added) > new Date()) {
                    return setAlert('Wait until auction starts!', 'warning')
                }

                if (toggleDialog) {
                    values.producturl = `${window.origin}/${global.storeDetails.id}/productView/${values.id}`
                    if (parseInt(product.content_head5) == 1) {
                        if (values.hard_bid) {
                            values.hard_bid = parseInt(values.hard_bid)
                        }
                        values.wsprice = parseInt(values.wsprice)
                        values.userid = user.id
                        bidReverseConfirm(values)
                    } else {
                        bidConfirm(values)
                    }
                    formik.setTouched({})
                    setToggleDialog(false)
                } else {
                    formik.setSubmitting(false)
                    setToggleDialog(true)
                }
            } else {
                setAlert('Please Add A Credit Card To Bid!', 'error')
                handleRedirectInternal(history, 'dashboard/cards')
            }
        },
    })

    useEffect(() => {
        formik.values.hard_bid = props.hard
    }, [props.hard])

    useEffect(() => {
        if (get_all_card_details) {
            setCards(get_all_card_details.records)
        }
    }, [get_all_card_details])

    useEffect(() => {
        // console.log(product, 'this is he product inide useeffect')
        if (product && Number(product.next_bid) >= Number(formik.values.wsprice)) {
            formik.setFieldValue('wsprice', '')
        } else {
            setToggleDialog(false)
        }
    }, [product])
    useEffect(() => {
        if (product) {
            formik.setFieldValue('id', product.id)
            formik.setFieldValue('bid_increment', product.incrementamt)
            if (parseInt(product.content_head5) == 1) {
                if (
                    parseInt(product.next_bid) == parseInt(product.wprice) &&
                    parseInt(product.next_bid) == parseInt(product.sprice)
                ) {
                    setReverseNextBid(product.next_bid)
                } else {
                    setReverseNextBid(
                        parseInt(product.next_bid) - parseInt(2 * parseInt(product.incrementamt)),
                    )
                }
            }
        }
    }, [product])

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                formik.setFieldValue('email', user.email)
                formik.setFieldValue('first_name', user.first_name)
                formik.setFieldValue('last_name', user.last_name)
            }
        }
    }, [isAuthenticated, user])
    // console.log(formik.values, toggleDialog, 'this si tppgale ')
    const bidAmount = [
        {
            label: 'Enter bid',
            name: 'wsprice',
            type: 'number',
            placeholder:
                product?.content_head5 == '1'
                    ? `${'Max bid'} ${currencyFormat(reverse_nextbid, '', '', 0)}`
                    : product && product.next_bid
                    ? `${'Min bid'} ${currencyFormat(product.next_bid, '', '', 0)}`
                    : '',
            class: '',
            size: 'small',
            autoFocus: false,
            formik: formik,
            onChange: (e) => {
                const { value } = e.target
                const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/
                if (!value || regex.test(value.toString())) {
                    formik.setFieldValue('wsprice', value)
                }
            },
            step: product?.incrementamt,
            min: product?.content_head5 == '1' ? 0 : product?.next_bid,
            max: product?.content_head5 == '1' ? reverse_nextbid : 0,
        },
    ]

    useEffect(() => {
        setBidpopuptext(
            global.storeConfigration?.bid_confirmation_text?.value
                ? global.storeConfigration.bid_confirmation_text.value
                : '',
        )
    }, [])
    // console.log(product, 'this is product')
    useEffect(() => {
        if (bidpopuptext != '') {
            var bid_text = global.storeConfigration?.bid_confirmation_text?.value.replace(
                /{{amount}}/g,
                currencyFormat(formik.values.wsprice),
            )

            setBidpopuptext(bid_text)
        }
    }, [formik.values.wsprice])

    const reversebid = () => {
        if (parseInt(props.is_registered) == 0 && parseInt(props.private_auction) == 1) {
            setAlert('Please register auction and continue!', 'error')
        } else {
            bidLikeSnag({
                wsprice: dropPrice,
                id: product.id,
                placedate: servertime,
                aid: product.lotof,
            })
        }
    }
    const cb = (childData) => {
        // console.log(
        //     '0' + childData.seconds,
        //     'childData.seconds',
        //     parseFloat(60) - parseFloat(childData.seconds - 1),
        // )
        //setTimer(childData)
        if (childData.seconds == '00') {
            // console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnnnnn")
            setDropPrice(parseFloat(product.rprice))
        } else {
            setDropPrice(
                Math.ceil(
                    parseFloat(
                        parseFloat(product.sprice) -
                            parseFloat(
                                parseFloat(
                                    (parseFloat(product.sprice) -
                                        parseFloat(
                                            parseInt(product.bids) > 0
                                                ? parseFloat(product.wprice) >
                                                  parseFloat(product.rprice)
                                                    ? product.wprice
                                                    : product.rprice
                                                : product.rprice,
                                        )) /
                                        parseFloat(60),
                                ).toFixed(2),
                            ) *
                                (parseFloat(60) -
                                    parseFloat(
                                        '0' + childData.seconds === '00'
                                            ? 60
                                            : childData.seconds === '59'
                                            ? 60
                                            : childData.seconds,
                                    )),
                    ).toFixed(2),
                ),
            )
        }
    }
    useEffect(() => {
        // console.log('===============================================', responseStatus)
        if (responseStatus) {
            if (responseStatus.from == 'bidlikesnag') {
                if (responseStatus.status == 'success') {
                    setAlert('Congratulations, you won this item!', 'success')
                } else {
                    setAlert(responseStatus.error, 'error')
                }
                clearResponse()
            }
        }
    }, [responseStatus])
    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' && product.content_head5 == 0 ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div
                                className={`biddingCnt d-flex justify-content-between align-items-start ${
                                    !isAuthenticated && 'notLogged'
                                }`}
                            >
                                {isAuthenticated ? (
                                    <>
                                        {Object.values(mapData(bidAmount))}
                                        {product.bidtopstatus === 'winner' ? (
                                            <SecondaryButton
                                                label={
                                                    formik.values.hard_bid === '1'
                                                        ? 'Increase hard bid'
                                                        : 'Increase proxy bid'
                                                }
                                                type="submit"
                                                btnSize="small"
                                            />
                                        ) : (
                                            <PrimaryButton
                                                label={
                                                    formik.values.hard_bid === '1'
                                                        ? props.shortText
                                                            ? props.shortText
                                                            : 'Place hard bid'
                                                        : props.shortText
                                                        ? props.shortText
                                                        : 'Place proxy bid'
                                                }
                                                type="submit"
                                                btnSize="small"
                                            />
                                        )}
                                    </>
                                ) : (
                                    <PrimaryButton
                                        label="Login to bid"
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                )}
                            </div>
                        </form>
                    ) : product.content_head5 == 1 &&
                      showtimer &&
                      product.market_status === 'open' ? (
                        <div
                            className={`biddingCnt d-flex justify-content-end align-items-start ${
                                !isAuthenticated && 'notLogged'
                            }`}
                        >
                            {isAuthenticated ? (
                                <>
                                    <SnagTimer
                                        cb={cb}
                                        snag={1}
                                        floor={product.rprice}
                                        sprice={product.sprice}
                                        date_added={product.date_added}
                                        date_closed={product.date_closed}
                                    />
                                    <div className="customInput bidNow">
                                        <p className="bidAmnt">
                                            Bid Price: $<b>{dropPrice}</b>
                                        </p>
                                        <PrimaryButton
                                            label="Bid Now"
                                            btnSize="small"
                                            onClick={() => reversebid()}
                                        />
                                    </div>
                                </>
                            ) : (
                                <PrimaryButton
                                    label="Login to bid"
                                    btnSize="small"
                                    onClick={() => handleRedirectInternal(history, 'login')}
                                />
                            )}
                        </div>
                    ) : null}

                    <CustomDialog
                        title={'Confirm bid'}
                        open={toggleDialog}
                        maxWidth={'md'}
                        function={changeDialogStatus}
                    >
                        {global.storeConfigration?.bid_confirmation_text?.value ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: bidpopuptext,
                                }}
                            ></div>
                        ) : (
                            <>
                                <h5>{'You are about to place a bid'}!</h5>
                                <h5>{'Please confirm if you want to continue with this action'}</h5>
                            </>
                        )}
                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                disabled={formik.isSubmitting}
                                label={'Submit'}
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
